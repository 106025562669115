@use '@/styles/utils/mixins.scss' as *;

.pageSection {
  scroll-margin-top: 7.5rem;
}

.pageContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 15.625rem;

  @include mob() {
    gap: 12.5rem;
  }
  .pageContentContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.75rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
      &.pageContentImageContainer {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &.smallContent {
      grid-template-columns: repeat(2, 1fr);
      @include tab() {
        grid-template-columns: repeat(1, 1fr);
      }
      .pageContentSection {
        grid-column: span 1;
      }
    }

    .pageContentHeadingSection {
      .bannerImage {
        border-radius: 1.25rem;
        max-width: 100%;
        height: auto;
        object-fit: cover;

        @include mob() {
          width: 100%;
        }
      }
    }
  }
  .pageContentSection {
    padding-top: 0.75rem;
    grid-column: span 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include tab() {
      grid-column: span 1;
    }

    .pageContentBtn {
      width: fit-content;
      @include mob() {
        width: 100%;
      }
    }
  }
  .pageContentDetails {
    @include tab() {
      grid-column: span 1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      margin-bottom: 0.625rem;

      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
    h1 {
      font-size: 6rem;
    }
    h2 {
      font-size: 4.5rem;
    }
    h3 {
      font-size: 3rem;
    }
    h5 {
      font-size: 2.125rem;
    }
    h6 {
      font-size: 1.5rem;
    }
    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
}
